<template>
    <div class="pa-3">
        <v-card flat :loading="loadingView">
            <v-card-title>
                Manage inventory qty

                <v-spacer></v-spacer>

                <v-btn depressed color="grey darken-4" class="white--text text-none" @click="editDialog = true">
                    <v-icon left>mdi-plus</v-icon>
                    New item
                </v-btn>
            </v-card-title>
            <v-card-subtitle>Creat or edit items without barcode</v-card-subtitle>
            <v-card-text>
                <v-row class="mb-2">
                    <v-col cols="3">
                        <v-text-field :value="styleFilter" @input="setStyleFilter" placeholder="Style" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field :value="sizeFilter" @input="setSizeFilter" placeholder="Size" flat hide-details solo-inverted clearable @keydown.enter="loadItems"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-select :value="typeFilter" @input="setTypeFilter" :items="productTypes" placeholder="Item type" item-text="name" item-value="id" flat hide-details solo-inverted clearable @change="loadItems"></v-select>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="itemHeaders" :items="items" :footer-props="paginationOptions" item-key="id" :options="pagination" :loading="loading">
                     <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="warningDialog(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="editDialog" width="500" persistent>
            <v-card>
                <v-card-title>Configure item</v-card-title>
                <v-card-subtitle>Set the configuration for this qty item</v-card-subtitle>
                <v-card-text>
                    <v-form ref="form">
                        <div v-if="selectedItem != null">
                            <v-text-field v-model="selectedItem.style" label="Style" placeholder="Style" outlined hide-details></v-text-field>
                            <v-text-field v-model="selectedItem.size" label="Size" placeholder="Size" outlined hide-details class="mt-3"></v-text-field>
                            <v-text-field v-model="selectedItem.qty" label="Qty" placeholder="Qty" outlined hide-details class="mt-3"></v-text-field>
                        </div>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed class="text-none" @click="closeDialog">Cancel</v-btn>
                    <v-btn depressed class="white--text text-none" color="grey darken-4" :loading="saving" @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed class="text-none" @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn depressed color="error" class="white--text text-none" :loading="saving" @click="removeItem">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        itemHeaders                 : [
            { text: 'Style', value: 'style', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Qty', value: 'qty', sortable: true },
            { text: 'Actions', value: 'actions', sortable: false }
        ],
        pagination                  : {
            sortBy: ['style']
        },
        paginationOptions           : { itemsPerPageOptions: [ 50, 75, 100, -1 ] },

        loadingView                 : false,
        loading                     : false,
        saving                      : false,

        editDialog                  : false,
        deleteDialog                : false,

        selectedItem                : null,
    }),
    computed: {
        ...mapGetters({
            productTypes    : 'productType/items',
            items           : 'inventory/managementQty/items',
            styleFilter     : 'inventory/managementQty/styleFilter',
            sizeFilter      : 'inventory/managementQty/sizeFilter',
            typeFilter      : 'inventory/managementQty/typeFilter',
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadProductTypes    : 'productType/load',
            refreshItems        : 'inventory/managementQty/loadItems',
            setStyleFilter      : 'inventory/managementQty/setStyleFilter',
            setSizeFilter       : 'inventory/managementQty/setSizeFilter',
            setTypeFilter       : 'inventory/managementQty/setTypeFilter',

            saveItem            : 'inventory/managementQty/saveItem',
            deleteItem          : 'inventory/managementQty/deleteItem',
        }),
        async init() {
            try {
                this.clearItem();
                await this.loadProductTypes();
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
        },
        async loadItems() {
            try {
                this.loading = true;
                await this.refreshItems();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        editItem( item ) {
            this.selectedItem = item;
            this.editDialog = true;
        },
        async save() {
            try {
                this.saving = true;
                await this.saveItem( this.selectedItem );
                this.editDialog = false;
            }
            catch( error ) {
                console.log( error );
            }
            finally {
                this.saving = false;
            }
        },
        closeDialog() {
            this.editDialog = false;
            this.clearItem();
        },
        warningDialog( item ) {
            this.selectedItem = item;
            this.deleteDialog = true;
        },
        async removeItem() {
            try {
                this.saving = true;
                await this.deleteItem( this.selectedItem );
                this.deleteDialog = false;
            }
            catch( error ) {
                console.log( error );
            }
            finally {
                this.saving = false;
            }
        },
        clearItem() {
            this.selectedItem = {
                productId   : 0,
                style       : '',
                size        : '',
                qty         : 0
            };
        }
    }
}
</script>

<style lang="scss" scoped>

</style>